import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';

import imgSEO from '../../assets/img/services/graphicdesign.jpg';

import SimilarCard from '../../components/similar.card';

import similar1 from '../../assets/img/services/thumbnail/copywriting.jpg';
import similar2 from '../../assets/img/services/thumbnail/website.jpg';
import similar3 from '../../assets/img/services/thumbnail/dev-mobile.jpg';
import similar4 from '../../assets/img/services/thumbnail/developpement.jpg';
import SEO from '../../components/seo';
import CtaSection from '../../components/cta.section';
import cta from '../../assets/img/illustrations/design.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Graphic design | Agence digitale | MDS Digital Agency"
      description="Votre graphic design est un élément capital. Cerner le prisme de l’identité de votre marque nous permet de vous offrir une charte graphique personnalisée."
      keywords="graphic design"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2>Graphic design: identité visuelle, web design, impressions</h2>
      <p>
        Nous sommes conscients que nous vivons dans une société d’image où la première impression
        peut s’avérer décisive. Votre graphic design est donc un élément capital. C’est pourquoi
        nous prenons le temps de cerner le prisme de l’identité de votre marque afin de vous offrir
        une <strong>charte graphique</strong> à la fois authentique et originale.
      </p>

      <p>
        Nos graphistes définissent les éléments de votre charte graphique pour générer une identité
        dynamique dans votre graphic design:
      </p>
      <ul>
        <li>
          Votre <strong>logo</strong>
        </li>
        <li>
          Les <strong>couleurs</strong>
        </li>
        <li>
          La <strong>police d'écriture</strong>
        </li>
      </ul>
      <p>
        Notre équipe décline ensuite cette charte graphique pour{' '}
        <strong>créer vos différents supports de communication</strong>:
      </p>
      <ul>
        <li>
          Votre <strong>site web</strong>
        </li>
        <li>
          Vos <strong>cartes de visite</strong>
        </li>
        <li>
          Vos <strong>flyers</strong>
        </li>
        <li>
          Vos <strong>dépliants</strong>
        </li>
        <li>
          Vos articles de <strong>merchandising</strong>
        </li>
        <li>...</li>
      </ul>

      <p>
        MDS s’occupe de votre graphic design. Vos projets graphiques sont totalement pris en charge,{' '}
        <strong>de la conception à la production</strong>. Nous développons votre identité visuelle
        complète et
        <strong> nous nous chargeons également de l’impression</strong>. Vous n’avez pas besoin de
        vous éparpiller.
      </p>
      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Je veux me différencier de mes concurrents. Je suis à la recherche de supports de communication qui reflètent mon expérience, mes valeurs et mes missions. Pouvez-vous m’aider?"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Copywriting" url="/services/copywriting/" img={similar1} />
          <SimilarCard text="Création de sites internet" url="/services/website/" img={similar2} />
          <SimilarCard
            text="Développement mobile"
            url="/services/developpement-mobile/"
            img={similar3}
          />
          <SimilarCard text="Développement" url="/services/developpement/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
